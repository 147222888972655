
          @import "@/assets/css/vars.scss";
        






























































































































































































































































































































.home-modal-component {
  position: absolute;
  width: 424px;
  min-height: 1436px;
  background: #ffffff;
  box-shadow: 0px 5px 11px rgba(0, 0, 0, 0.05);
  left: calc(50% - 212px);
  margin: 0 auto;
  z-index: 11;
  margin: 60px 0;
  padding: 50px 30px;
  .material-icons {
    position: absolute;
    font-size: 25px;
    left: calc(100% - 30px);
    top: 25px;
    cursor: pointer;
  }
  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    font-family: Xiaomi-Regular;
    letter-spacing: -0.01em;
    color: #22242c;
    margin-bottom: 25px;
  }
  .flex-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    .flex-wrap-left,
    .flex-wrap-right {
      width: 100%;
      height: 163px;
      border: 1px solid #e4e4e4;
      border-radius: 11px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      span {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 17px;
        text-align: center;
        color: #4e5565;
        display: block;
        max-width: 115px;
      }
      img {
        display: block;
        width: 100%;
      }
      &.selected {
        background: #ffffff;
        border: 1px solid #ffcca9;
        box-sizing: border-box;
        box-shadow: 0px 0px 11px rgba(255, 105, 0, 0.3);
        border-radius: 11px;
      }
    }
  }
  .selected-item-notification {
    margin-top: 25px;
    margin-bottom: 30px;
    span {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 17px;
      font-family: Xiaomi-Regular;
      color: #888c95;
    }
  }
  .select-wrap {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 17px;
    span {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 15px;
      color: #757983;
      display: inline-block;
      margin-bottom: 7px;
    }
    select {
      width: 366px;
      height: 45px;
      border: 1px solid #dbdbdb;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 0 16px;
      font-family: Work Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 18px;
      color: #5f646f;
      &:focus {
        outline: none;
      }
    }
  }
  .upload-ticket-wrap {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 17px;
    cursor: pointer;
    & > span {
      margin-bottom: 7px;
    }
    span {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 15px;
      color: #757983;
      display: inline-block;
    }
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 366px;
      height: 45px;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 16px;
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      text-align: center;
      color: #8c8f97;
      background: #efefef;
    }
  }
  .img-success {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    font-size: 14px;
    img {
      display: block;
      width: 20px;
      margin-left: 10px;
    }
  }
  .terms-wrap {
    display: flex;
    align-items: center;
    justify-content: space-around;
    input {
      border: 1px solid #a6abae;
      box-sizing: border-box;
      border-radius: 6px;
      display: block;
    }
    span {
      font-size: 14px;
      a {
        color: #ff6900;
      }
    }
  }
  button {
    width: 366px;
    height: 51px;
    background: #ff6900;
    border: none;
    outline: none;
    margin-top: 60px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
    &.disabled {
      background: #f79048;
    }
  }
}
@media only screen and (max-width: 430px) {
  .home-modal-component {
    left: 0;
    width: 100%;
    height: unset;
    .flex-wrap {
      flex-direction: column;
      .flex-wrap-left {
        margin-bottom: 10px;
      }
    }
    .select-wrap {
      select {
        width: 100%;
      }
    }
    .upload-ticket-wrap {
      div {
        width: 100%;
      }
    }
    .terms-wrap {
      text-align: center;
    }
    button {
      width: 100%;
    }
  }
}
